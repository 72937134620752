import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Form from "./Form";
import Summary from "./Summary";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

const Terminal = (props) => {
  const [flightMark, setFlightMark] = useState(0);
  const [landingMark, setLandingMark] = useState(0);
  const [outrunMark, setOutrunMark] = useState(0);

  const { onClose, selectedValue, open } = props;

  const reset = () => {
    setFlightMark(0);
    setLandingMark(0);
    setOutrunMark(0);
  };

  const handleClose = () => {
    reset();
    onClose(selectedValue);
  };

  const handleMark = (part, mark) => {
    switch (part) {
      case "flight":
        setFlightMark(mark);
        break;
      case "landing":
        setLandingMark(mark);
        break;
      case "outrun":
        setOutrunMark(mark);
        break;
      default:
        console.error("Error");
    }
  };

  const saveMark = () => {
    const { result } = props;
    const total = 20 + flightMark + landingMark + outrunMark;
    props.onSave({
      mark: {
        flight: -flightMark,
        outrun: -outrunMark,
        landing: -landingMark,
        result_id: result.id,
        total,
      },
    });
  };

  if (!props.result) {
    return null;
  }

  const handleNextAthlete = () => {
    reset();
    props.onNextAthlete();
  }

  const nextButton = () => {
    if (props.next) {
      return (
        <Button onClick={handleNextAthlete} color="primary" autoFocus>
          Next: {props.next && props.next.name}
        </Button>
      );
    } else {
      return (
        <Button onClick={props.onComplete} color="secondary" autoFocus>
          Complete Round
        </Button>
      );
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        <Box display="flex">
          <Avatar src={props.jumper && props.jumper.photo} />
          <div style={{ marginLeft: 24, fontSize: 28 }}>
            {props.result.name} ({props.result.nation})
          </div>
        </Box>

        <div style={{ float: "right", marginTop: -44 }}>
          Bib: {props.result.bib}
        </div>
      </DialogTitle>
      <DialogContent>
        {props.summary ? (
          <Summary
            average={props.result.mark_average}
            marks={props.result.marks}
            experts_mark={props.result.experts_mark}
            flight={flightMark}
            landing={landingMark}
            outrun={outrunMark}
          />
        ) : (
          <Form
            handleMark={handleMark}
            flightMark={flightMark}
            landingMark={landingMark}
            outrunMark={outrunMark}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {props.summary ? (
          nextButton()
        ) : (
          <Button onClick={saveMark} color="primary" autoFocus>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

Terminal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // selectedValue: PropTypes.string.isRequired,
};

export default Terminal;
