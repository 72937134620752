import React, { useState } from "react";
import logo from "../../logo.svg";
import "../../App.css";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Link from "@material-ui/core/Link";
import MuiAlert from "@material-ui/lab/Alert";
import Alert from '@mui/material/Alert';
import { login } from "./authSlice";
import { connect } from "react-redux";
import {
  useNavigate,
} from 'react-router-dom';



const mapState = (state) => ({
  authToken: state.auth.token,
  error: state.auth.error,
  isLoading: state.auth.isLoading,
});

const actionCreators = {
  login,
};

const useStyles = makeStyles((theme) => ({
  links: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 24,
  },
  background: {
    background: `url("/background${Math.ceil(Math.random()*3)}.jpg") no-repeat center center fixed`,
    backgroundSize: "cover",
    position: "fixed",
    top: 0,
    left: 0,
    minHeight: "100%",
    /* Set up proportionate scaling */
    width: "100%",
    height: "auto",
  },
  panel: {
    opacity: 0.9,
    backgroundColor: '#fff',
    padding: 16,
  }
}));

const Login = ({ login, authToken, error, isLoading }) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();
  const classes = useStyles();

  const handlePassword = (e) => {
    setPassword(e.currentTarget.value);
  };

  const handleEmail = (e) => {
    setEmail(e.currentTarget.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    login(email, password).then((data) => {
      navigate('/dashboard');
    });
  };

  return (
    <div className={classes.background}>
      <div className="container">
        <div className={classes.panel}>
        {error && (
          <MuiAlert
            severity="error"
            variant="filled"
            style={{ marginBottom: 24 }}
          >
            {error}
          </MuiAlert>
        )}
        <img src="https://upload.wikimedia.org/wikipedia/en/thumb/0/07/F%C3%A9d%C3%A9ration_internationale_de_ski_%28logo%29.svg/1200px-F%C3%A9d%C3%A9ration_internationale_de_ski_%28logo%29.svg.png" />
        <h1 style={{ textAlign: 'center' }}>Sign in</h1>
        <Alert severity="info">Use your TD forms login data</Alert>
        <form className="form" onSubmit={handleSubmit}>
          <FormControl>
            <InputLabel>Login</InputLabel>
            <Input
              label="Email"
              id="email"
              onChange={handleEmail}
              type="text"
            />
          </FormControl>
          <FormControl style={{ marginTop: 24 }}>
            <InputLabel>Password</InputLabel>
            <Input
              label="Password"
              id="password"
              onChange={handlePassword}
              type="password"
            />
          </FormControl>
          <Button type="submit" variant="outlined" color="primary" className="form__custom-button">
            Log in
          </Button>

          <Typography className={classes.links}>
            <Link href="https://sjforms.fis-ski.com" target="_blank">
              TD Tool
            </Link>
            <Link href="https://elearning.fis-ski.com" target="_blank">
              E-Learning
            </Link>
          </Typography>
        </form>
        </div>
      </div>
    </div>
  );
};

export default connect(mapState, actionCreators)(Login);
