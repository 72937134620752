import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";

import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";

export const LinearProgress1 = withStyles({
  barColorPrimary: {
    background: "#4caf50",
  },
  colorPrimary: {
    background: "#edf7ed",
  },
})(LinearProgress);

export const LinearProgress2 = withStyles({
  colorPrimary: {
    background: "#fff4e5",
  },
  barColorPrimary: {
    background: "#ff9800",
  },
})(LinearProgress);

export const LinearProgress3 = withStyles({
  colorPrimary: {
    background: "#fdecea",
  },
  barColorPrimary: {
    background: "#f44336",
  },
})(LinearProgress);

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  overall: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      margin: 1,
      width: 200,
      height: 200,
      textAlign: "center",
    },
  },
  icon: {
    fontSize: 64,
  },
  description: {
    marginTop: 8,
    fontWeight: 600,
  },
});

const Statistics = (props) => {
  const { onClose, open, marks, results } = props;

  const classes = useStyles();
  const stats = [0, 0, 0];

  const handleClose = () => {
    onClose();
  };

  if (!(marks && results)) {
    return null;
  }

  results.map((result) => {
    const user_mark = marks[result.id];
    if (user_mark) {
      const diff = Math.abs(user_mark.total - result.mark_average);
      if (diff <= 0.5) {
        stats[0] += 1;
      } else if (diff > 0.5 && diff <= 1.0) {
        stats[1] += 1;
      } else {
        stats[2] += 1;
      }
    }
  });

  const marks_size = Object.keys(marks).length;

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Your statistics</DialogTitle>
      <DialogContent>
        {marks_size > 0 ? (
          <>
            <div className={classes.overall}>
              <Paper elevation={3}>
                <h5>Your overall score</h5>
                {stats[0] > stats[1] && stats[0] > stats[2] && (
                  <>
                    <ThumbUpIcon
                      className={classes.icon}
                      style={{ color: "#4caf50" }}
                    />
                    <div
                      className={classes.description}
                      style={{ color: "#4caf50" }}
                    >
                      Great!
                    </div>
                  </>
                )}
                {stats[1] > stats[0] && stats[1] > stats[2] && (
                  <>
                    <ThumbUpIcon
                      className={classes.icon}
                      style={{ color: "#ff9800" }}
                    />
                    <div
                      className={classes.description}
                      style={{ color: "#ff9800" }}
                    >
                      Middling
                    </div>
                  </>
                )}
                {stats[2] > stats[0] && stats[2] > stats[1] && (
                  <>
                    <ThumbDownIcon
                      className={classes.icon}
                      style={{ color: "#f44336" }}
                    />
                    <div
                      className={classes.description}
                      style={{ color: "#f44336" }}
                    >
                      Poorly
                    </div>
                  </>
                )}
              </Paper>
            </div>

            <div style={{ marginTop: 36 }}>
              <Typography>
                {stats[0]} marks with difference to judges' marks less than 0.5
              </Typography>
              <LinearProgress1
                variant="determinate"
                value={(stats[0] / marks_size) * 100}
              />
            </div>
            <div style={{ marginTop: 36 }}>
              <Typography>
                {stats[1]} marks with difference to judges' marks between 0.5
                and 1.0
              </Typography>
              <LinearProgress2
                variant="determinate"
                value={(stats[1] / marks_size) * 100}
              />
            </div>
            <div style={{ marginTop: 36 }}>
              <Typography>
                {stats[2]} marks with difference to judges' marks greater than
                1.0
              </Typography>
              <LinearProgress3
                variant="determinate"
                value={(stats[2] / marks_size) * 100}
              />
            </div>
          </>
        ) : (
          <Alert severity="info">
            No data yet. At least one mark for this round needed.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Statistics.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // selectedValue: PropTypes.string.isRequired,
};

export default Statistics;
