import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Terminal from "./Terminal";
import Statistics from "./Statistics";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import flags from "../../utils/flags";
import { score } from "../../utils/marks";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import FlightIcon from "@material-ui/icons/Flight";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";

import {
  fetchCompetition,
  fetchJumpers,
  fetchRounds,
  saveMark,
} from "./competitionSlice";

const mapState = (state) => ({
  competition: state.competition.data,
  jumpers: state.competition.jumpers,
  rounds: state.competition.rounds,
  marks: state.competition.marks,
});

const actionCreators = {
  fetchCompetition,
  fetchRounds,
  fetchJumpers,
  saveMark,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  mark: {
    fontSize: 13,
    margin: "auto",
  },
  marks: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  wellDone: {
    fontSize: 11,
    backgroundColor: "#4caf50",
  },
  notBad: {
    fontSize: 11,
    backgroundColor: "#ff9800",
  },
  inaccurate: {
    fontSize: 11,
    backgroundColor: "#f44336",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 24,
    height: 24,
    border: `2px solid ${theme.palette.background.paper}`,
    marginTop: 12,
    marginLeft: 12,
  },
}))(Avatar);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export const Competition = (props) => {
  const [activeItem, setActiveItem] = React.useState({
    open: false,
    result: null,
  });

  const { id } = useParams();

  const [isStatsOpen, setStatsOpen] = React.useState(false);
  const [summary, setSummary] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [nextAthlete, setNextAthlete] = React.useState(null);
  const [judgesPopoverData, setJudgesPopoverData] = React.useState(null);
  const [detailsPopoverData, setDetailsPopoverData] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { competition, jumpers, marks, rounds } = props;
  const handleMenuClick = (event) => {
    rounds.length > 1 && setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    props.fetchRounds(id).then((response) => {
      props.fetchCompetition(id, response[0]);
    });
  }, []);

  const handleJudgesPopoverOpen = (e, marks) => {
    e.stopPropagation();
    setJudgesPopoverData({ marks, anchorEl: e.target });
  };

  const handleJudgesPopoverClose = () => {
    setJudgesPopoverData(null);
  };

  const handleDetailsPopoverOpen = (e, detailed_marks) => {
    e.stopPropagation();
    setDetailsPopoverData({ detailed_marks, anchorEl: e.target });
  };

  const handleDetailsPopoverClose = () => {
    setDetailsPopoverData(null);
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleClickOpen = (result) => () => {
    setActiveItem({ open: true, result });
  };

  const handleClose = () => {
    setActiveItem({ open: false, result: null });
    setSummary(false);
  };

  const handleSave = (params) => {
    props.saveMark(id, params).then((result) => {
      setOpenAlert(true);
      setSummary(true);
      setNextAthlete(getNextAthlete());
    });
  };

  const getNextAthlete = () => {
    const index = competition.results.findIndex(
      (item) => item.id === activeItem.result.id
    );
    const newArr = [
      ...competition.results.slice(index + 1),
      ...competition.results.slice(0, index),
    ];
    return newArr.find((r) => !marks[r.id]);
  };

  const handleNextAthlete = () => {
    setActiveItem({ open: true, result: nextAthlete });
    setSummary(false);
  };

  const handleComplete = () => {
    setActiveItem({ open: false, result: null });
    setSummary(false);
  };

  const handleStats = () => {
    setStatsOpen(true);
  };

  const handleStatsClose = () => {
    setStatsOpen(false);
  };

  const round = (el) => {
    switch (el) {
      case "Q0":
        return "Qualification";
      case "C0":
        return "Round 1";
      case "C1":
        return "Round 2";
      case "C2":
        return "Round 3";
      case "C3":
        return "Round 4";
      default:
        return "-";
    }
  };

  const classes = useStyles();

  // duplicated
  const markClassName = (value, average) => {
    const v = score(value, average);
    if (v === 0) {
      return classes.wellDone;
    } else if (v === 1) {
      return classes.notBad;
    } else {
      return classes.inaccurate;
    }
  };

  return (
    <div>
      <Container maxWidth="md">
        {competition && jumpers && marks ? (
          <>
            <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
              <Link color="inherit" href="/">
                Dashboard
              </Link>
              <Link color="inherit" href="/competitions">
                Competitions
              </Link>
              <Typography color="textPrimary">
                {competition.hill ? competition.hill.location.name : competition.remarks } {competition.date}
              </Typography>
            </Breadcrumbs>

            <Grid container spacing={1}>
              <Grid container item sm={6}>
                <Typography variant="h1">
                  {competition.hill ? competition.hill.location.name : competition.remarks} {competition.date}
                </Typography>
              </Grid>
              <Grid container item sm={6}>
                <div
                  style={{
                    marginRight: 0,
                    marginLeft: "auto",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      marginRight: 8,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ marginRight: 8, fontSize: 16 }}>
                      Progress
                    </span>
                    <Avatar style={{ fontSize: 13 }}>
                      {Math.floor(
                        (competition.results.filter((el) => marks[el.id])
                          .length /
                          competition.results.length) *
                          100
                      )}
                      %
                    </Avatar>
                  </div>

                  <ButtonGroup
                    color="primary"
                    aria-label="outlined primary button group"
                    style={{
                      display: "block",
                      marginTop: 24,
                    }}
                  >
                    <Button variant="outlined" onClick={handleStats}>
                      Statistics
                    </Button>

                    <Button
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant="outlined"
                      color="primary"
                      onClick={handleMenuClick}
                    >
                      {round(competition.round)}
                      {rounds.length > 1 && <ArrowDropDownIcon />}
                    </Button>
                  </ButtonGroup>

                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    {rounds.map((el) => {
                      return (
                        <StyledMenuItem
                          key={el}
                          onClick={() => props.fetchCompetition(id, el)}
                        >
                          <ListItemText primary={round(el)} />
                        </StyledMenuItem>
                      );
                    })}
                  </StyledMenu>
                </div>
              </Grid>
            </Grid>

            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Bib</TableCell>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">Nation</TableCell>
                    <TableCell align="center">Jump</TableCell>
                    <TableCell align="center">Your mark</TableCell>
                    <TableCell align="center">Judges avg.</TableCell>
                    <TableCell align="center">SUs avg.</TableCell>
                    <TableCell align="center" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {competition &&
                    competition.results &&
                    competition.results.map((row, index) => {
                      const userMark = marks[row.id];
                      return (
                        <TableRow key={`${row.name}-${index}`}>
                          <TableCell align="center">{row.bib}.</TableCell>
                          <TableCell>
                            <Avatar
                              variant="rounded"
                              src={
                                row.fiscodex &&
                                jumpers[row.fiscodex] &&
                                jumpers[row.fiscodex].photo
                              }
                              width="32"
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="center">
                            <img
                              src={flags[row.nation]}
                              alt={row.nation}
                              width="32"
                            />
                          </TableCell>
                          <TableCell align="center">{row.distance}</TableCell>
                          <TableCell align="center">
                            {userMark && (
                              <Avatar
                                variant="rounded"
                                className={classes.mark}
                                onMouseEnter={(e) =>
                                  handleDetailsPopoverOpen(e, {
                                    flight: userMark.flight,
                                    outrun: userMark.outrun,
                                    landing: userMark.landing,
                                  })
                                }
                                onMouseLeave={handleDetailsPopoverClose}
                              >
                                {userMark.total}
                              </Avatar>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {userMark ? (
                              <Badge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                badgeContent={
                                  <SmallAvatar
                                    className={markClassName(
                                      userMark.total,
                                      row.mark_average
                                    )}
                                  >
                                    {Math.abs(
                                      row.mark_average - userMark.total
                                    ).toFixed(1)}
                                  </SmallAvatar>
                                }
                              >
                                <Avatar
                                  variant="rounded"
                                  className={classes.mark}
                                  onMouseEnter={(e) =>
                                    handleJudgesPopoverOpen(e, row.marks)
                                  }
                                  onMouseLeave={handleJudgesPopoverClose}
                                >
                                  {row.mark_average}
                                </Avatar>
                              </Badge>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {userMark && row.experts_mark.total ? (
                              <Badge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                badgeContent={
                                  <SmallAvatar
                                    className={markClassName(
                                      userMark.total,
                                      row.experts_mark.total
                                    )}
                                  >
                                    {Math.abs(
                                      row.experts_mark.total - userMark.total
                                    ).toFixed(1)}
                                  </SmallAvatar>
                                }
                              >
                                <Avatar
                                  variant="rounded"
                                  className={classes.mark}
                                  onMouseEnter={(e) =>
                                    handleDetailsPopoverOpen(
                                      e,
                                      row.experts_mark
                                    )
                                  }
                                  onMouseLeave={handleDetailsPopoverClose}
                                >
                                  {row.experts_mark.total}
                                </Avatar>
                              </Badge>
                            ) : (
                              "-"
                            )}
                          </TableCell>

                          <TableCell>
                            <Button
                              variant="contained"
                              onClick={handleClickOpen(row)}
                              disabled={!!userMark}
                            >
                              Edit
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <div style={{ marginTop: 36 }}>
            <LinearProgress />
          </div>
        )}
      </Container>

      <Terminal
        open={activeItem.open}
        result={activeItem.result}
        next={nextAthlete}
        jumper={
          activeItem && activeItem.result && jumpers[activeItem.result.fiscodex]
        }
        onClose={handleClose}
        onSave={handleSave}
        onNextAthlete={handleNextAthlete}
        onComplete={handleComplete}
        summary={summary}
      />

      <Statistics
        open={isStatsOpen}
        results={competition && competition.results}
        marks={marks}
        onClose={handleStatsClose}
      />

      <Popover
        style={{ pointerEvents: "none" }}
        id={id}
        open={Boolean(judgesPopoverData && judgesPopoverData.anchorEl)}
        onClose={handleJudgesPopoverClose}
        anchorEl={judgesPopoverData && judgesPopoverData.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 8,
          }}
        >
          {judgesPopoverData &&
            judgesPopoverData.marks.map((el, index) => (
              <Paper
                key={index}
                style={{
                  fontSize: 11,
                  padding: 4,
                  margin: 2,
                  textAlign: "center",
                }}
              >
                {el.total}
                <div>
                  <img
                    src={flags[el.member.nation]}
                    alt={el.member.nation}
                    width="12"
                  />
                </div>
              </Paper>
            ))}
        </div>
      </Popover>

      <Popover
        style={{ pointerEvents: "none" }}
        id={id}
        open={Boolean(detailsPopoverData && detailsPopoverData.anchorEl)}
        onClose={handleDetailsPopoverClose}
        anchorEl={detailsPopoverData && detailsPopoverData.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 8,
          }}
        >
          {detailsPopoverData && detailsPopoverData.detailed_marks && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 8,
              }}
            >
              <Paper
                style={{
                  fontSize: 11,
                  padding: 4,
                  margin: 2,
                  textAlign: "center",
                }}
              >
                {detailsPopoverData.detailed_marks.flight}
                <div>
                  <FlightIcon fontSize="small" color="action" />
                </div>
              </Paper>

              <Paper
                style={{
                  fontSize: 11,
                  padding: 4,
                  margin: 2,
                  textAlign: "center",
                }}
              >
                {detailsPopoverData.detailed_marks.landing}
                <div>
                  <FlightLandIcon fontSize="small" color="action" />
                </div>
              </Paper>

              <Paper
                style={{
                  fontSize: 11,
                  padding: 4,
                  margin: 2,
                  textAlign: "center",
                }}
              >
                {detailsPopoverData.detailed_marks.outrun}
                <div>
                  <AirportShuttleIcon fontSize="small" color="action" />
                </div>
              </Paper>
            </div>
          )}
        </div>
      </Popover>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        message="Your mark has been saved!"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseAlert}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default connect(mapState, actionCreators)(Competition);
