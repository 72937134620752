import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import Container from "@material-ui/core/Container";
import Alert from "@mui/material/Alert";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import WcIcon from "@mui/icons-material/Wc";

import Chip from "@mui/material/Chip";
import Skeleton from "@mui/material/Skeleton";
import Paper from "@mui/material/Paper";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

import LineChart from "./LineChart";
import flags from "../../utils/flags";

import {
  fetchAllMarks,
  fetchCompetitions,
  fetchAthletes,
  fetchNations,
  fetchTdUsers,
} from "./marksSlice";
import { connect } from "react-redux";

const mapState = (state) => ({
  marks: state.marks.marks,
  isLoading: state.marks.isLoading,
  roles: state.auth.data.roles,
  filterData: state.marks.filters,
});

const actionCreators = {
  fetchAllMarks,
  fetchCompetitions,
  fetchNations,
  fetchTdUsers,
  fetchAthletes,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  item: {
    padding: 16,
    marginTop: 8,
    marginBottom: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 16,
  },
  icon: {
    fontSize: 16,
  },
  description: {
    marginLeft: 8,
    fontWeight: 600,
  },
}));

const displayColor = (diff) => {
  if (diff <= 0.5) {
    return <span style={{ color: "#4caf50" }}>{diff}</span>;
  } else if (diff > 0.5 && diff <= 1.0) {
    return <span style={{ color: "#ff9800" }}>{diff}</span>;
  } else {
    return <span style={{ color: "#f44336" }}>{diff}</span>;
  }
};

export function Marks({
  fetchAllMarks,
  fetchCompetitions,
  fetchNations,
  fetchTdUsers,
  fetchAthletes,
  marks,
  filterData,
  isLoading,
  roles,
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [filters, setFilters] = React.useState();
  const [sort, setSort] = React.useState();
  const [pageSize, setPageSize] = React.useState(100);

  const isAdmin = roles.find((r) => r.name === "admin");

  const columns = [
    {
      field: "result_competition_date",
      headerName: "Date",
      type: "date",
      minWidth: 100,
      valueGetter: (params) => new Date(params.row.competition.date),
    },
    {
      field: "result_competition_id",
      headerName: "Competition",
      type: "singleSelect",
      minWidth: 150,
      valueOptions: filterData.competitions.map((el) => ({
        label: `${el.date} ${el.hill ? el.hill.location.name : el.remarks}`,
        value: el.id,
      })),
      valueGetter: (params) => {
        return params.row.competition.hill
          ? params.row.competition.hill.location.name
          : params.row.competition.remarks;
      },
    },
    {
      field: "result_competition_gender",
      headerName: "Type",
      width: 40,
      type: "singleSelect",
      sortable: false,
      valueOptions: [
        { label: "Men", value: 0 },
        { label: "Ladies", value: 1 },
        { label: "Mixed", value: 2 },
      ],

      valueGetter: (params) => `${params.row.competition.gender}`,
      renderCell: (params) => {
        switch (params.value) {
          case "men":
            return <ManIcon />;
          case "ladies":
            return <WomanIcon />;
          case "mixed":
            return <WcIcon />;
          default:
          // no default
        }
      },
    },
    {
      field: "result_name",
      headerName: "Jump",
      type: "singleSelect",
      valueOptions: filterData.athletes.map((el) => ({
        label: el.name,
        value: el.name,
      })),
      minWidth: 150,
      valueGetter: (params) => params.row.result.name,
    },
    {
      field: "result_nation",
      headerName: "Nat.",
      width: 40,
      type: "singleSelect",
      valueOptions: filterData.nations.map((el) => ({
        label: el.shortname,
        value: el.shortname,
      })),
      valueGetter: (params) => params.row.result.nation,
      renderCell: (params) =>
        params.value && (
          <img alt={params.value} width="24" src={flags[params.value]} />
        ),
    },
    {
      field: "td_user_id",
      headerName: "User",
      minWidth: 150,
      type: "singleSelect",
      filterable: isAdmin,
      valueOptions: filterData.users.map((el) => ({
        label: el.name,
        value: el.id,
      })),
      valueGetter: (params) => params.row.td_user.name,
    },
    {
      field: "td_user_td_delegate_nation_id",
      headerName: "Nat.",
      width: 40,
      type: "singleSelect",
      sortable: false,
      filterable: isAdmin,
      valueOptions: filterData.nations.map((el) => ({
        label: el.shortname,
        value: el.id,
      })),
      valueGetter: (params) => `${params.row.td_user.nation}`,
      renderCell: (params) => {
        return params.row.td_user.nation ? (
          <img alt={params.value} width="24" src={flags[params.value]} />
        ) : (
          "-"
        );
      },
    },
    {
      field: "total",
      headerName: "Mark",
      type: "number",
      width: 60,
    },
    {
      field: "flight",
      headerName: "Flight",
      type: "number",
      width: 70,
    },
    {
      field: "landing",
      headerName: "Landing",
      type: "number",
      width: 70,
    },
    {
      field: "outrun",
      headerName: "Outrun",
      type: "number",
      width: 70,
    },
    {
      field: "result_superusers_mark_avg",
      headerName: "SU avg",
      type: "number",
      width: 70,
      valueGetter: (params) => params.row.experts_mark_avg,
    },
    {
      field: "experts_mark_avg_diff",
      headerName: "Δ SU",
      type: "number",
      width: 70,
      sortable: false,
      filterable: false,
      valueGetter: (params) => params.row.experts_mark_avg_diff,
      renderCell: (params) => displayColor(params.value),
    },
    {
      field: "result_mark_avg",
      headerName: "RM avg",
      type: "number",
      width: 70,
      valueGetter: (params) => params.row.real_mark_avg,
    },
    {
      field: "real_mark_avg_diff",
      headerName: "Δ RM",
      type: "number",
      width: 70,
      sortable: false,
      filterable: false,
      valueGetter: (params) => params.row.real_mark_avg_diff,
      renderCell: (params) => displayColor(params.value),
    },
  ];

  React.useEffect(() => {
    fetchAllMarks();
    fetchCompetitions();
    fetchAthletes();
    fetchNations();
    fetchTdUsers();
  }, []);

  const handleChangePage = (newPage) => {
    console.log("handleChangePage", newPage);
    fetchAllMarks({ page: newPage, filters, sort });
    setPage(newPage);
  };

  const shouldDrawChart = !!(
    filters &&
    filters.td_user_id_eq &&
    filters.result_competition_id_eq
  );

  const handleFilter = (e) => {
    console.log(e);
    const q = { m: e.linkOperator };
    e.items.forEach((el) => {
      let field = el.columnField;
      switch (el.operatorValue) {
        case "is":
        case "=":
          field += "_eq";
          break;
        case "not":
        case "!=":
          field += "_not_eq";
          break;
        case "isAnyOf":
          field += "_matches_any";
          break;
        case ">":
        case "after":
          field += "_gt";
          break;
        case ">=":
        case "onOrAfter":
          field += "_gteq";
          break;
        case "<":
        case "before":
          field += "_lt";
          break;
        case "<=":
        case "onOrBefore":
          field += "_lteq";
          break;
        default:
          field += "_cont";
      }

      q[field] = el.value;
    });

    fetchAllMarks({ filters: q, page: 0, sort });
    setFilters(q);
    setPage(0);
  };

  const handleSort = (e) => {
    let s;
    if (e.length === 0) {
      s = undefined;
    } else {
      s = e.map((el) => `${el.field} ${el.sort}`);
    }
    fetchAllMarks({ sort: s, page: 0, filters });
    setSort(s);
    setPage(0);
  };

  return (
    <Container maxWidth="md">
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Typography color="textPrimary">Marks</Typography>
      </Breadcrumbs>

      <Grid container spacing={6} style={{ marginTop: 0 }}>
        <Grid item xs={12}>
          {shouldDrawChart ? (
            <LineChart marks={marks} />
          ) : (
            <Alert severity="info">
              Choose a Judge and Competition to see the line chart
            </Alert>
          )}
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            justify="center"
            rowSpacing={5}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid key={1} xs={4} item>
              <Paper className={classes.item}>
                {!isLoading ? (
                  <>
                    <FactCheckIcon sx={{ fontSize: 72, color: "#ff7961" }} />
                    <Chip
                      color="primary"
                      label={
                        marks.mark_avg
                          ? parseFloat(marks.mark_avg).toFixed(2)
                          : "-"
                      }
                      variant="outlined"
                    />{" "}
                    <div>Selected marks avg.</div>
                  </>
                ) : (
                  <Skeleton variant="rectangular" width={256} height={120} />
                )}
              </Paper>
            </Grid>
            <Grid key={2} xs={4} item>
              <Paper className={classes.item}>
                {!isLoading ? (
                  <>
                    {" "}
                    <SupervisorAccountIcon
                      sx={{ fontSize: 72, color: "#ff7961" }}
                    />
                    <Chip
                      color="primary"
                      label={
                        marks.experts_mark_avg
                          ? parseFloat(marks.experts_mark_avg).toFixed(2)
                          : "-"
                      }
                      variant="outlined"
                    />{" "}
                    <div>Superusers' marks avg.</div>
                  </>
                ) : (
                  <Skeleton variant="rectangular" width={256} height={120} />
                )}
              </Paper>
            </Grid>
            <Grid key={3} xs={4} item>
              <Paper className={classes.item}>
                {!isLoading ? (
                  <>
                    <ScoreboardIcon sx={{ fontSize: 72, color: "#ff7961" }} />
                    <Chip
                      color="primary"
                      label={
                        marks.real_mark_avg
                          ? parseFloat(marks.real_mark_avg).toFixed(2)
                          : "-"
                      }
                      variant="outlined"
                    />{" "}
                    <div>Real marks avg.</div>
                  </>
                ) : (
                  <Skeleton variant="rectangular" width={256} height={120} />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container style={{ marginTop: 24 }}>
        <Grid item xs={12}>
          <Grid
            container
            justify="center"
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid key={1} xs={12} item>
              <Box sx={{ height: 840, width: "100%" }}>
                <DataGridPro
                  components={{
                    Toolbar: GridToolbar,
                  }}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        flight: false,
                        landing: false,
                        outrun: false,
                      },
                    },
                  }}
                  page={page}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[100]}
                  pagination
                  rows={marks.data}
                  columns={columns}
                  paginationMode="server"
                  onPageChange={handleChangePage}
                  filterMode="server"
                  onFilterModelChange={handleFilter}
                  sortingMode="server"
                  onSortModelChange={handleSort}
                  rowCount={marks.total_entries}
                  getRowId={(el) => el.id}
                  loading={isLoading}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default connect(mapState, actionCreators)(Marks);
