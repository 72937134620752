import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { blue } from "@material-ui/core/colors";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import FlightIcon from "@material-ui/icons/Flight";
import FlightLandIcon from "@material-ui/icons/FlightLand";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import GetApp from "@material-ui/icons/GetApp";
import PanTool from "@material-ui/icons/PanTool";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  deduction: {
    backgroundColor: "#4caf50",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#4caf50",
    },
  },
  deduction2: {
    backgroundColor: "#999",
    "&:hover": {
      backgroundColor: "#999",
    },
  },
});

function Form(props) {
  const classes = useStyles();

  const handleFlight = (value) => {
    // max 5.0
    const total = props.flightMark + value;
    if (total <= 0 && total >= -5) {
      props.handleMark("flight", total);
    } else {
      console.log("Error");
    }
  };

  const handleLanding = (value) => {
    // max 5.0
    const total = props.landingMark + value;
    if (total <= 0 && total >= -5) {
      props.handleMark("landing", total);
    } else {
      console.log("Error");
    }
  };

  const handleOutrun = (value) => {
    // max 7.0
    const total = props.outrunMark + value;
    if (total <= 0 && total >= -7) {
      props.handleMark("outrun", total);
    } else {
      console.log("Error");
    }
  };

  const value = 20.0 + props.flightMark + props.landingMark + props.outrunMark;

  return (
    <DialogContentText id="alert-dialog-description">
      <Box display="flex" justifyContent="center">
        <TextField
          id="outlined-basic"
          label="Your mark"
          variant="outlined"
          value={value.toFixed(1)}
        />
      </Box>

      <Box style={{ marginTop: 32 }}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <h5>
              <FlightIcon /> Flight
            </h5>
            <TextField
              variant="outlined"
              value={props.flightMark.toFixed(1)}
              size="small"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <h5>
              <FlightLandIcon /> Landing
            </h5>
            <TextField
              variant="outlined"
              value={props.landingMark.toFixed(1)}
              size="small"
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <h5>
              <AirportShuttleIcon /> Outrun
            </h5>
            <TextField
              variant="outlined"
              value={props.outrunMark.toFixed(1)}
              size="small"
              disabled
            />
          </Grid>

          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              // color="primary"
              className={classes.deduction}
              onClick={() => handleFlight(-0.5)}
            >
              +0.5
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              className={classes.deduction}
              onClick={() => handleLanding(-0.5)}
            >
              +0.5
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              className={classes.deduction}
              onClick={() => handleOutrun(-0.5)}
            >
              +0.5
            </Button>
          </Grid>

          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="secondary"
              onClick={() => handleFlight(0.5)}
            >
              -0.5
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="secondary"
              onClick={() => handleLanding(0.5)}
            >
              -0.5
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="secondary"
              onClick={() => handleOutrun(0.5)}
            >
              -0.5
            </Button>
          </Grid>

          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              className={classes.deduction2}
              onClick={() => handleFlight(-1.0)}
            >
              +1.0
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              className={classes.deduction2}
              onClick={() => handleLanding(-1.0)}
            >
              +1.0
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              className={classes.deduction2}
              onClick={() => handleOutrun(-1.0)}
            >
              +1.0
            </Button>
          </Grid>

          <Grid item xs={6}>
            <h5>
              <PanTool /> Touching ground
            </h5>
            <Button
              fullWidth
              size="large"
              variant="contained"
              className={classes.deduction2}
              onClick={() => handleOutrun(-5.0)}
            >
              -5.0
            </Button>
          </Grid>
          <Grid item xs={6}>
            <h5>
              <GetApp /> Fall
            </h5>
            <Button
              fullWidth
              size="large"
              variant="contained"
              className={classes.deduction2}
              onClick={() => handleOutrun(-7.0)}
            >
              -7.0
            </Button>
          </Grid>
        </Grid>
      </Box>
    </DialogContentText>
  );
}

Form.propTypes = {};

export default Form;
