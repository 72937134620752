import { Line } from "react-chartjs-2";
import React, { useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import { Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend, } from "chart.js";
ChartJS.register(CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend);

function LineChartComponent({ marks }) {
  if (marks.data.length === 0) {
    return <Skeleton variant="circular" width={256} height={256} />;
  }

  console.log(marks);

  const labels = marks.data.map((el) => el.result.name );
  const datasets = [
    {
      label: "Mark",
      data: marks.data.map((el) => el.total ),
      borderColor: "#ff6361",
      backgroundColor:  "#ff6361",
      tension: 0.5
    },
    {
      label: "SU Mark Avg.",
      data: marks.data.map((el) => el.experts_mark_avg ),
      borderColor: "#ffa600",
      backgroundColor:  "#ffa600",
      tension: 0.5
    },
    {
      label: "Real Mark Avg.",
      data: marks.data.map((el) => el.real_mark_avg ),
      borderColor: "#003f5c",
      backgroundColor:  "#003f5c",
      tension: 0.5,
      // fill: true,
    },
  ];

  return (
    <Line
      options={{
        width: "300",
        height: "300",
      }}
      data={{
        labels: labels,
        datasets: datasets,
      }}
    />
  );
}

export default LineChartComponent;
