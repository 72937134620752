export const score = (value, average) => {
  if (Math.abs(average - value) <= 0.5) {
    return 0;
  }

  if (
    (average >= 17 &&
      Math.abs(average - value) > 0.5 &&
      Math.abs(average - value) <= 0.7) ||
    (average < 17 && average >= 14 && Math.abs(average - value) <= 1.0) ||
    (average < 14 && Math.abs(average - value) <= 1.5)
  ) {
    return 1;
  }

  return 2;
};
