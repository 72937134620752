import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import Container from "@material-ui/core/Container";
import StarIcon from "@mui/icons-material/Star";
import Tooltip from "@mui/material/Tooltip";
import flags from "../../utils/flags";
import PieChartComponent from "./PieChart";
import Skeleton from "@mui/material/Skeleton";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";

import {
  fetchLatestCompetitions,
  fetchUserProfile,
  fetchMostActiveUsers,
  fetchMostOftenJudgedCompetitions,
} from "./dashboardSlice";
import { connect } from "react-redux";

const mapState = (state) => ({
  profile: state.dashboard.profile,
  roles: state.auth.data.roles,
  mostActiveUsers: state.dashboard.mostActiveUsers,
  mostOftenJudgedCompetitions: state.dashboard.mostOftenJudgedCompetitions,
});

const actionCreators = {
  fetchLatestCompetitions,
  fetchUserProfile,
  fetchMostActiveUsers,
  fetchMostOftenJudgedCompetitions,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
  },
  item: {
    padding: 16,
    marginTop: 8,
    marginBottom: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 16,
  },
  icon: {
    fontSize: 16,
  },
  description: {
    marginLeft: 8,
    fontWeight: 600,
  },
}));

export function DashboardAdmin({
  fetchUserProfile,
  fetchMostOftenJudgedCompetitions,
  fetchMostActiveUsers,
  competitions,
  profile,
  mostActiveUsers,
  mostOftenJudgedCompetitions,
  roles,
}) {
  const classes = useStyles();

  React.useEffect(() => {
    fetchUserProfile();
    fetchMostActiveUsers();
    fetchMostOftenJudgedCompetitions();
  }, []);

  return (
    <Container maxWidth="md">
      <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: 20 }}>
        <Typography color="textPrimary">Dashboard</Typography>
      </Breadcrumbs>

      <Grid container spacing={6} style={{ marginTop: 0 }}>
        <Grid item xs={12}>
          <Grid
            container
            justify="center"
            rowSpacing={5}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid key={1} xs={4} item>
              <Paper className={classes.item}>
                {profile.competitions_total ? (
                  <>
                    <FmdGoodIcon sx={{ fontSize: 72, color: "#ff7961" }} />
                    <div>
                      <Chip
                        color="primary"
                        label={profile.competitions_total}
                        variant="outlined"
                      />{" "}
                      <span style={{ paddingLeft: 8 }}>Competitions</span>
                    </div>
                  </>
                ) : (
                  <Skeleton variant="rectangular" width={256} height={120} />
                )}
              </Paper>
            </Grid>
            <Grid key={2} xs={4} item>
              <Paper className={classes.item}>
                {profile.competitions_total ? (
                  <>
                    {" "}
                    <PeopleAltIcon sx={{ fontSize: 72, color: "#ff7961" }} />
                    <div>
                      <Chip
                        color="primary"
                        label={profile.users_total}
                        variant="outlined"
                      />{" "}
                      <span style={{ paddingLeft: 8 }}>Users</span>
                    </div>
                  </>
                ) : (
                  <Skeleton variant="rectangular" width={256} height={120} />
                )}
              </Paper>
            </Grid>
            <Grid key={3} xs={4} item>
              <Paper className={classes.item}>
                {profile.competitions_total ? (
                  <>
                    <ScoreboardIcon sx={{ fontSize: 72, color: "#ff7961" }} />
                    <div>
                      <Chip
                        color="primary"
                        label={profile.marks_total}
                        variant="outlined"
                      />{" "}
                      <span style={{ paddingLeft: 8 }}>Marks</span>
                    </div>
                  </>
                ) : (
                  <Skeleton variant="rectangular" width={256} height={120} />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={6} style={{ marginTop: 0 }}>
        <Grid item xs={6}>
          <h3>Users' marks vs. real marks</h3>
          <div style={{ padding: 64 }}>
            <PieChartComponent marks={profile.real_marks} />
          </div>
        </Grid>
        <Grid item xs={6}>
          <h3>Users' marks vs. SU marks</h3>
          <div style={{ padding: 64 }}>
            <PieChartComponent marks={profile.su_marks} />
          </div>
        </Grid>
      </Grid>

      <Grid>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <h3>Most active users</h3>

            {mostActiveUsers ? (
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    {mostActiveUsers.map((el) => (
                      <TableRow
                        key={el.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Avatar alt="Remy Sharp" src={el.image} />
                        </TableCell>
                        <TableCell align="left">{el.name}</TableCell>
                        <TableCell align="center">
                          <img
                            src={flags[el.nation]}
                            alt={el.nation}
                            width="24"
                          />
                        </TableCell>
                        <TableCell align="right">
                          {el.superjudge && (
                            <Tooltip title="Superjudge">
                              <StarIcon color="secondary" />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Chip label={el.total} max={1000000} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            <h3>Most often judged competitions</h3>
            {mostOftenJudgedCompetitions ? (
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    {mostOftenJudgedCompetitions.map((el) => (
                      <TableRow
                        key={el.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {el.hill && (
                            <Avatar alt="Remy Sharp" src={el.hill.image} />
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {el.hill && el.hill.location.name}
                          <Typography
                            component="div"
                            variant="body2"
                            color="#999"
                          >
                            {el.date}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <img
                            src={flags[el.nation]}
                            alt={el.nation}
                            width="24"
                          />
                        </TableCell>
                        <TableCell align="center">
                          {el.hill && (
                            <img
                              width="24"
                              src={flags[el.hill.location.nation.shortname]}
                              alt={el.hill.location.nation.shortname}
                            />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Chip label={el.total} max={1000000} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default connect(mapState, actionCreators)(DashboardAdmin);
