import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router";
import { Navigate, Outlet } from "react-router-dom";

// const AuthRoute = (props) => {
//   const { isAuthUser, type } = props;
//   if (type === "guest" && isAuthUser) return <Navigate to="/competitions" />;
//   else if (type === "private" && !isAuthUser) return <Navigate to="/login" />;

//   return <Route {...props} />;
// };

const AuthRoute = ({ user, redirectPath = '/login' }) => {
  console.log(user);
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

const mapStateToProps = (state) => ({
  isAuthUser: state.auth.isAuthUser,
  username: state.auth.username,
});

export default connect(mapStateToProps)(AuthRoute);
