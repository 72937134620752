import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../pages/login/authSlice';
import competitionsReducer from '../pages/competitions/competitionsSlice';
import competitionReducer from '../pages/competition/competitionSlice';
import dashboardReducer from '../pages/dashboard/dashboardSlice';
import marksReducer from '../pages/marks/marksSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    competitions: competitionsReducer,
    competition: competitionReducer,
    dashboard: dashboardReducer,
    marks: marksReducer,
  },
});
